import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import DeclarationLetterPNG from '../../assets/images/DeclarationLetter.png';

const NotificationLetter = () => {
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    const NotificationLetterIsDisplayedBefore = localStorage.getItem('NotificationLetterIsDisplayedBefore');
    if (NotificationLetterIsDisplayedBefore === null) {
      setIsShow(true);
      localStorage.setItem('NotificationLetterIsDisplayedBefore', true);
    }
  }, []);

  return (
    isShow && <div className={styles.overlay}>
      <img src={DeclarationLetterPNG} alt="" className={styles.centeredImage} onClick={() => setIsShow(false)} />
    </div>
  );
};

export default NotificationLetter;
