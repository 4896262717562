import React, { useState, useEffect } from 'react';
import download from '@/assets/images/header/appDownload/download.png';
import xiquLogo from '@/assets/images/header/appDownload/xiquLogo.png';
import download2 from '@/assets/images/header/appDownload/download2.png';
import classNames from 'classnames';
import LazyImage from '../../../LazyImage';
import styles from './index.module.scss';

const WebAppDownloadHeader = () => {
  const [activeContainer, setActiveContainer] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const homeHeaderBg = () => import('@/assets/images/header/appDownload/home_header_bg.jpg').then((module) => ({ default: module.default }));
  const homeHeaderBg2 = () => import('@/assets/images/header/appDownload/home_header_bg2.jpg').then((module) => ({ default: module.default }));
  const logo = () => import('@/assets/images/header/appDownload/logo.png').then((module) => ({ default: module.default }));
  const [imagesLoaded, setImagesLoaded] = useState({
    homeHeaderBg: false,
    homeHeaderBg2: false,
    logo: false,
  });

  useEffect(() => {
    if (activeContainer === 1 && !imagesLoaded.homeHeaderBg2) {
      setImagesLoaded((prev) => ({ ...prev, homeHeaderBg2: true }));
    } else if (activeContainer === 0 && !imagesLoaded.homeHeaderBg) {
      setImagesLoaded((prev) => ({ ...prev, homeHeaderBg: true }));
    }
    if (!imagesLoaded.logo) {
      setImagesLoaded((prev) => ({ ...prev, logo: true }));
    }
  }, [activeContainer, imagesLoaded]);

  const handleActiveContainer = () => {
    setActiveContainer((prevActiveContainer) => {
      const newContainer = prevActiveContainer === 0 ? 1 : 0;
      return newContainer;
    });
  };

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  const banner1 = (
    <header className={classNames(styles['carousel-container'], { [styles.active]: activeContainer === 0 })}>
      {imagesLoaded.homeHeaderBg && <LazyImage importFunc={homeHeaderBg} alt="" className={styles.homeHeaderBg} />}
      {imagesLoaded.logo && <LazyImage importFunc={logo} alt="" className={styles.logo} />}
      <img src={download} alt="" className={styles.download} onClick={() => window.location.href = 'https://portal-resource-global.j-make.cn/download/apps/Leishi_official_release.apk'} />
    </header>
  );

  const banner2 = (
    <header className={classNames(styles['carousel-container'], { [styles.active]: activeContainer === 1 })}>
      {imagesLoaded.homeHeaderBg2 && <LazyImage importFunc={homeHeaderBg2} alt="" className={styles.homeHeaderBg} />}
      <img src={xiquLogo} alt="" className={styles.xiquLogo} />
      <img src={download2} alt="" className={styles.download2} onClick={() => window.location.href = 'https://portal-resource-global.j-make.cn/download/apps/Jmake_opera_official.apk'} />
    </header>
  );

  const arrowLeftBox = (
    <div className={classNames(styles['arrow-box'], { [styles.visible]: isVisible }, styles.left)} onClick={handleActiveContainer}>
      <div className={classNames(styles.arrow, styles.left)} />
    </div>
  );

  const arrowRightBox = (
    <div className={classNames(styles['arrow-box'], { [styles.visible]: isVisible }, styles.right)} onClick={handleActiveContainer}>
      <div className={classNames(styles.arrow, styles.right)} />
    </div>
  );

  return (
    <div className={styles.carousel} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {banner1}
      {banner2}
      {arrowLeftBox}
      {arrowRightBox}
    </div>
  );
};

export default WebAppDownloadHeader;