import React from 'react';
import { useLocation } from 'react-router-dom';
import layoutConfig from '../layoutConfig';
import Navs from '../components/Navs';
import ScrollToTop from '../components/ScrollToTop';
import NotificationLetter from '../components/NotificationLetter';
import styles from './index.module.scss';

const Layout = () => {
  const location = useLocation();
  const path = location.pathname;
  const { header: Header, main: Main, footer: Footer } = layoutConfig[path] || {};

  return (
    <div className={styles.layout}>
      <div className={styles.navs}><Navs /></div>
      {Header && <Header />}
      {Main && <Main />}
      {Footer && <Footer />}
      <NotificationLetter />
      <ScrollToTop />
    </div>
  );
};

export default Layout;