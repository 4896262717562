import React from "react";
import { useMediaQuery } from 'react-responsive';
import WebHome from './Web';
import MobileHome from './Mobile';


const HomeHeader = () => {
    const isDesktop = useMediaQuery({ minWidth: 768 }); // 目前PC和平板的尺寸没有做区分
    const isMobile = useMediaQuery({ maxWidth: 767 });

    return (
        <div>
            {isDesktop && <WebHome />}
            {isMobile && <MobileHome />}
        </div>
    );
};

export default HomeHeader;