import React from "react";
import { useMediaQuery } from 'react-responsive';
import WebNavs from './Web';
import MobileNavs from './Mobile';


const Navs = () => {
    const isDesktop = useMediaQuery({ minWidth: 768 }); // 目前PC和平板的尺寸没有做区分
    const isMobile = useMediaQuery({ maxWidth: 767 });

    return (
        <div>
            {isDesktop && <WebNavs />}
            {isMobile && <MobileNavs />}
        </div>
    );
};

export default Navs;