import React, { useState } from 'react';
import classNames from 'classnames';
import music_library from '@/assets/images/main/advantage/music_library.jpg';
import richclassification from '@/assets/images/main/advantage/richclassification.png';
import library_update from '@/assets/images/main/advantage/library_update.jpg';
import weChat_Songs from '@/assets/images/main/advantage/weChat_Songs.jpg';
import KaraokeShow from '@/assets/images/main/advantage/KaraokeShow.jpg';
import contentadvantages from '@/assets/images/main/advantage/contentadvantages.jpg';
import Technicalprecipitation from '@/assets/images/main/advantage/Technicalprecipitation.jpg';
import Karaokehardware from '@/assets/images/main/advantage/Karaokehardware.jpg';
import ProductOperations from '@/assets/images/main/advantage/ProductOperations.jpg';
import Cooperationchannels from '@/assets/images/main/advantage/Cooperationchannels.jpg';
import styles from './index.module.scss';

const WebAdvantageMain = () => {
  const [activeIndexProduct, setActiveIndexProduct] = useState(0);
  const [activeIndexPlatform, setActiveIndexPlatform] = useState(0);

  const handleProductButtonClick = (index) => {
    setActiveIndexProduct(index);
  };

  const handlePlatformButtonClick = (index) => {
    setActiveIndexPlatform(index);
  };

  return (
    <section className={styles['lei-slideinfo']}>
      <div>
        <div className={styles['lei-slideinfo-product']}>
          <div>
            <div className={styles['lei-slideinfo-title']}>
              <h2>产品特点</h2>
              <p></p>
            </div>
            <div className={styles['lei-slideinfo-content']}>
              <h2>
                <div className={styles['btn-group']} role="group" aria-label="title">
                  {['专业曲库', '分类丰富', '曲库更新', '微信点歌', 'K歌秀场'].map((title, index) => (
                    <button
                      key={index}
                      data-index={index}
                      type="button"
                      className={classNames(styles.btn, styles['btn-secondary'], { [styles.active]: activeIndexProduct === index })}
                      onClick={() => handleProductButtonClick(index)}
                    >
                      <h4>{title}</h4>
                    </button>
                  ))}
                </div>
              </h2>
              <div className={styles['lei-slideinfo-content-pics']}>
                <ul>
                  {[music_library, richclassification, library_update, weChat_Songs, KaraokeShow].map((imgSrc, index) => (
                    <li key={index} data-index={index} className={classNames({ [styles.active]: activeIndexProduct === index })}>
                      <img className={styles.lazyload} src={imgSrc} alt="" />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={styles['lei-slideinfo-platform']}>
          <div className={styles['j-style1']}>
            <svg
              className={styles['fusion-big-half-circle-candy']}
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              width="100%"
              height="100"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              style={{ fill: '#fff', padding: 0 }}
            >
              <path d="M0 0 C55 180 100 0 100 0 Z"></path>
            </svg>
          </div>
          <div>
            <div className={styles['lei-slideinfo-title']}>
              <h2>平台优势</h2>
              <p></p>
            </div>
            <div className={styles['lei-slideinfo-content']}>
              <h2>
                <div className={styles['btn-group']} role="group" aria-label="title">
                  {['内容优势', '技术沉淀', 'K歌硬件', '产品运营', '合作渠道'].map((title, index) => (
                    <button
                      key={index}
                      data-index={index}
                      type="button"
                      className={classNames(styles.btn, styles['btn-secondary'], { [styles.active]: activeIndexPlatform === index })}
                      onClick={() => handlePlatformButtonClick(index)}
                    >
                      <h4>{title}</h4>
                    </button>
                  ))}
                </div>
              </h2>
              <div className={styles['lei-slideinfo-content-pics']}>
                <ul>
                  {[contentadvantages, Technicalprecipitation, Karaokehardware, ProductOperations, Cooperationchannels].map((imgSrc, index) => (
                    <li key={index} data-index={index} className={classNames({ [styles.active]: activeIndexPlatform === index })}>
                      <img className={styles.lazyload} src={imgSrc} alt="" />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WebAdvantageMain;
