import HomeHeader from './components/Home/HomeHeader';
import HomeMain from './components/Home/HomeMain';
import HomeFooter from './components/Home/HomeFooter';
import AboutUsHeader from './components/AboutUs/AboutUsHeader';
import AboutUsMain from './components/AboutUs/AboutUsMain';
import AboutUsFooter from './components/AboutUs/AboutUsFooter';
import AdvantageHeader from './components/Advantage/AdvantageHeader';
import AdvantageMain from './components/Advantage/AdvantageMain';
import AdvantageFooter from './components/Advantage/AdvantageFooter';
import AppDownloadHeader from './components/AppDownload/AppDownloadHeader';
import AppDownloadFooter from './components/AppDownload/AppDownloadFooter';

const layoutConfig = {
  '/': {
    header: HomeHeader,
    main: HomeMain,
    footer: HomeFooter,
  },
  '/aboutUs': {
    header: AboutUsHeader,
    main: AboutUsMain,
    footer: AboutUsFooter,
  },
  '/advantage': {
    header: AdvantageHeader,
    main: AdvantageMain,
    footer: AdvantageFooter,
  },
  '/appDownload': {
    header: AppDownloadHeader,
    footer: AppDownloadFooter,
  },
};

export default layoutConfig;
