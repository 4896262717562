import React from 'react';
import partner from '@/assets/images/main/aboutUs/partner.jpg';
import styles from './index.module.scss';

const MobileAboutUsMain = () => {
  return (
    <section className={styles['lei-slideinfo']}>
      <div className={styles['lei-info1']}>
        <div className={styles['lei-slideinfo-info']}>
          <div className={styles['lei-slideinfo-title']}>
            <h2>关于我们</h2>
            <p></p>
            <div style={{ textAlign: 'left' }}>
              成都雷石天地电子技术有限公司是一家专业级家庭K歌产品和服务的提供商。
              作为首屈一指的全场景音乐科技的探索者，雷石团队深耕音乐产业27年，产品在车载、商用、家用、闲时碎片化娱乐等多元化场景应用广泛。
              作为雷石家庭线产品的开拓者，成都雷石主攻电视大屏K歌、K歌音响等家用市场，专注于家庭互联网K歌平台和应用的开发及运营，致力于让专业级K歌走入千家万户。
              旗下K歌产品已实现OTT智能终端全覆盖，用户超过千万，遍布全国。旗下专业K歌平台为众多知名音响厂商提供一站式智能化解决方案，并与各大品牌音响厂商联合推出多款专业级家庭K歌设备，让用户足不出户即可享受专业的K歌服务和沉浸式K歌体验。
            </div>
            <h2 className={styles.slogan}>商务合作</h2>
            <div className={styles.contacts}>
              联系人：朱女士&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 联系电话：134 3806 3671
            </div>
            <h2 className={styles.slogan}>音响渠道合作</h2>
            <div className={styles.contacts}>
              联系人：岳先生&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 联系电话：181 8042 5350
            </div>
          </div>
        </div>
      </div>
      <div className={styles['lei-info2']}>
        <svg className="fusion-big-half-circle-candy" xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%"
          height="30" viewBox="0 0 100 100" preserveAspectRatio="none" style={{ fill: '#fff', padding: 0 }}>
          <path d="M0 0 C55 180 100 0 100 0 Z"></path>
        </svg>
        <div className={styles['lei-slideinfo-info']}>
          <div className={styles['lei-slideinfo-title']}>
            <h2>部分合作伙伴</h2>
            <p></p>
          </div>
          <div className={styles['lei-slideinfo-content']}>
            <div className={styles['lei-content-info']}>
              <div className={styles['lei-content-infos']}>
                <div>
                  <img className={styles.lazyload} src={partner} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MobileAboutUsMain;
