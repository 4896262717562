import React from "react";
import { useMediaQuery } from 'react-responsive';
import WebAboutUsMain from './Web';
import MobileAboutUsMain from './Mobile';

const AboutUsMain = () => {
    const isDesktop = useMediaQuery({ minWidth: 768 }); // 目前PC和平板的尺寸没有做区分
    const isMobile = useMediaQuery({ maxWidth: 767 });

    return (
        <div>
            {isDesktop && <WebAboutUsMain />}
            {isMobile && <MobileAboutUsMain />}
        </div>
    );
};

export default AboutUsMain;