import React from 'react';
import LazyImage from '../../../LazyImage';
import classNames from 'classnames';
import styles from './index.module.scss';

const WebAdvantageHeader = () => {
  const advHeaderBg = () => import('@/assets/images/header/advantage/adv-header-bg.jpg').then((module) => ({ default: module.default }));
  const tv = () => import('@/assets/images/header/advantage/tv.png').then((module) => ({ default: module.default }));

  return (
    <div className={styles.carousel}>
      <header className={classNames(styles['carousel-container'])}>
        <LazyImage importFunc={advHeaderBg} alt="" className={styles.advHeaderBg} />
        <LazyImage importFunc={tv} alt="" className={styles.tv} />
        <div className={classNames(styles['lei-slidepic-bg-word'], styles['carousel-image'])}>
          <h2 className={styles['lei-slidepic-bg-word-1']}>专注实时K歌体验</h2>
          <h3 className={styles['lei-slidepic-bg-word-2']}>专业K歌  唱出心声</h3>
        </div>
      </header>
    </div>
  );
};

export default WebAdvantageHeader;
