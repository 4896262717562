import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

const WebScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const docHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    if (scrollTop > docHeight / 10) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div 
      onClick={scrollToTop} 
      className={classNames(styles['scroll-to-top'], { [styles.visible]: isVisible })}
    >
      <div className={styles.arrow} />
    </div>
  );
};

export default WebScrollToTop;
