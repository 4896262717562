import React from 'react';
import LazyImage from '../../../LazyImage';
import classNames from 'classnames';
import styles from './index.module.scss';

const WebAboutUsHeader = () => {
  const aboutUsHeaderBg = () => import('@/assets/images/header/aboutUs/aboutUsHeaderBg.jpg').then((module) => ({ default: module.default }));
  const slogan = () => import('@/assets/images/header/aboutUs/slogan.png').then((module) => ({ default: module.default }));

  return (
    <div className={styles.carousel}>
      <header className={classNames(styles['carousel-container'])}>
        <LazyImage importFunc={aboutUsHeaderBg} alt="" className={styles.aboutUsHeaderBg} />
        <LazyImage importFunc={slogan} alt="" className={classNames(styles.slogan, styles['carousel-image'])} />
      </header>
    </div>
  );
};

export default WebAboutUsHeader;
