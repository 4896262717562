import React from 'react';
import logo from '@/assets/images/header/home/logo.png';
import { useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import styles from './index.module.scss';

const WebNavs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  const navs = [
    { title: '首页', path: '/', id: 1 },
    { title: '产品优势', path: '/advantage', id: 2 },
    { title: '应用下载', path: '/appDownload', id: 3 },
    { title: '关于我们', path: '/aboutUs', id: 4 },
  ];

  const handleSwitchNav = (nav) => {
    const { path } = nav;
    navigate(path);
  };

  return (
    <div className={styles.navs_box}>
      <img src={logo} alt="" className={styles.logo} />
      <div className={styles.navs_title}>
        {navs.map(nav => {
          return (
            <span
              key={nav.id}
              className={classNames(styles.nav_item, { [styles.active]: path === nav.path })}
              onClick={() => handleSwitchNav(nav)}
            >
              {nav.title}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default WebNavs;