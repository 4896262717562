import React from "react";
import { useMediaQuery } from 'react-responsive';
import WebAdvantageHeader from './Web';
import MobileAdvantageHeader from './Mobile';

const AdvantageMain = () => {
    const isDesktop = useMediaQuery({ minWidth: 768 }); // 目前PC和平板的尺寸没有做区分
    const isMobile = useMediaQuery({ maxWidth: 767 });

    return (
        <div>
            {isDesktop && <WebAdvantageHeader />}
            {isMobile && <MobileAdvantageHeader />}
        </div>
    );
};

export default AdvantageMain;