import React from 'react';
import classNames from 'classnames';
import music_library from '@/assets/images/main/advantage/music_library.jpg';
import richclassification from '@/assets/images/main/advantage/richclassification.png';
import library_update from '@/assets/images/main/advantage/library_update.jpg';
import weChat_Songs from '@/assets/images/main/advantage/weChat_Songs.jpg';
import KaraokeShow from '@/assets/images/main/advantage/KaraokeShow.jpg';
import contentadvantages from '@/assets/images/main/advantage/contentadvantages.jpg';
import Technicalprecipitation from '@/assets/images/main/advantage/Technicalprecipitation.jpg';
import Karaokehardware from '@/assets/images/main/advantage/Karaokehardware.jpg';
import ProductOperations from '@/assets/images/main/advantage/ProductOperations.jpg';
import Cooperationchannels from '@/assets/images/main/advantage/Cooperationchannels.jpg';
import styles from './index.module.scss';

const MobileAdvantageMain = () => {
  return (
    <section className={styles['lei-slideinfo']}>
      <div>
        <div className={styles['lei-slideinfo-product']}>
          <div>
            <div className={styles['lei-slideinfo-title']}>
              <h2>产品特点</h2>
              <p></p>
            </div>
            <div className={styles['lei-slideinfo-content']}>
              <div className={styles['lei-slideinfo-content-pics']}>
                <ul>
                  {[music_library, richclassification, library_update, weChat_Songs, KaraokeShow].map((imgSrc, index) => (
                    <li key={index} data-index={index} className={styles.active}>
                      <img className={styles.lazyload} src={imgSrc} alt="" />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={styles['lei-slideinfo-platform']}>
          <div className={styles['lei-style1']}>
            <svg className={styles['fusion-big-half-circle-candy']} xmlns="http://www.w3.org/2000/svg" version="1.1"
              width="100%"
              height="40" viewBox="0 0 100 100" preserveAspectRatio="none" style={{ fill: '#fff', padding: 0 }}>
              <path d="M0 0 C55 180 100 0 100 0 Z"></path>
            </svg>
          </div>
          <div>
            <div className={styles['lei-slideinfo-title']}>
              <h2>平台优势</h2>
              <p></p>
            </div>
            <div className={styles['lei-slideinfo-content']}>
              <div className={styles['lei-slideinfo-content-pics']}>
                <ul>
                  {[contentadvantages, Technicalprecipitation, Karaokehardware, ProductOperations, Cooperationchannels].map((imgSrc, index) => (
                    <li key={index} data-index={index} className={classNames(styles.active)}>
                      <img className={styles.lazyload} src={imgSrc} alt="" />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MobileAdvantageMain;
