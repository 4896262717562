import React from "react";
import { useMediaQuery } from 'react-responsive';
import WebScrollToTop from './Web';
import MobileScrollToTop from './Mobile';

const ScrollToTop = () => {
    const isDesktop = useMediaQuery({ minWidth: 768 }); // 目前PC和平板的尺寸没有做区分
    const isMobile = useMediaQuery({ maxWidth: 767 });

    return (
        <div>
            {isDesktop && <WebScrollToTop />}
            {isMobile && <MobileScrollToTop />}
        </div>
    );
};

export default ScrollToTop;