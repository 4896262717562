import React, { useState, useEffect } from 'react';

const LazyImage = ({ importFunc, alt, className }) => {
  const [src, setSrc] = useState(null);

  useEffect(() => {
    importFunc().then((module) => {
      setSrc(module.default);
    });
  }, [importFunc]);

  return src && <img src={src} alt={alt} className={className} />;
};

export default LazyImage;
