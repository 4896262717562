import React, { useState, useEffect } from 'react';
import download_phone from '@/assets/images/header/appDownload/download_phone.png';
import download2_phone from '@/assets/images/header/appDownload/download2_phone.png';
import xiquLogo_phone from '@/assets/images/header/appDownload/xiquLogo_phone.png';
import LazyImage from '../../../LazyImage';
import classNames from 'classnames';
import styles from './index.module.scss';

const MobileAppDownloadHeader = () => {
  const [activeContainer, setActiveContainer] = useState(0); // 用于跟踪当前活动的容器
  const [isVisible, setIsVisible] = useState(false);
  const home_header_bg = () => import('@/assets/images/header/appDownload/home_header_bg_phone.jpg').then((module) => ({ default: module.default }));
  const home_header_bg2 = () => import('@/assets/images/header/appDownload/home_header_bg2_phone.jpg').then((module) => ({ default: module.default }));
  const logo_phone = () => import('@/assets/images/header/appDownload/logo_phone.png').then((module) => ({ default: module.default }));
  const [imagesLoaded, setImagesLoaded] = useState({
    homeHeaderBg: false,
    homeHeaderBg2: false,
    logo: false,
  });

  useEffect(() => {
    if (activeContainer === 1 && !imagesLoaded.homeHeaderBg2) {
      setImagesLoaded((prev) => ({ ...prev, homeHeaderBg2: true }));
    } else if (activeContainer === 0 && !imagesLoaded.homeHeaderBg) {
      setImagesLoaded((prev) => ({ ...prev, homeHeaderBg: true }));
    }
    if (!imagesLoaded.logo) {
      setImagesLoaded((prev) => ({ ...prev, logo: true }));
    }
  }, [activeContainer, imagesLoaded]);

  const handleActiveContainer = () => {
    setActiveContainer((prevActiveContainer) => {
      const newContainer = prevActiveContainer === 0 ? 1 : 0;
      return newContainer;
    });
  };

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  // 第一张轮播图
  const banner1 = (
    <header className={classNames(styles['carousel-container'], { [styles.active]: activeContainer === 0 })}>
      {imagesLoaded.homeHeaderBg && <LazyImage importFunc={home_header_bg} alt="" className={styles.homeHeaderBg} />}
      {imagesLoaded.logo && <LazyImage importFunc={logo_phone} alt="" className={styles.logo} />}
      <img src={download_phone} alt="" className={styles.download} onClick={() => window.location.href = 'https://portal-resource-global.j-make.cn/download/apps/Leishi_official_release.apk'} />
    </header>
  );

  // 第二张轮播图
  const banner2 = (
    <header className={classNames(styles['carousel-container'], { [styles.active]: activeContainer === 1 })}>
      {imagesLoaded.homeHeaderBg2 && <LazyImage importFunc={home_header_bg2} alt="" className={styles.homeHeaderBg} />}
      <img src={xiquLogo_phone} alt="" className={styles.xiquLogo} />
      <img src={download2_phone} alt="" className={styles.download2} onClick={() => window.location.href = 'https://portal-resource-global.j-make.cn/download/apps/Jmake_opera_official.apk'} />
    </header>
  );

  // 左箭头
  const arrowLeftBox = (
    <div className={classNames(styles['arrow-box'], { [styles.visible]: isVisible }, styles.left)} onClick={handleActiveContainer}>
      <div className={classNames(styles.arrow, styles.left)} />
    </div>
  );

  // 右箭头
  const arrowRightBox = (
    <div className={classNames(styles['arrow-box'], { [styles.visible]: isVisible }, styles.right)} onClick={handleActiveContainer}>
      <div className={classNames(styles.arrow, styles.right)} />
    </div>
  );


  return (
    <div
      className={styles.carousel}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {banner1}
      {banner2}
      {arrowLeftBox}
      {arrowRightBox}
    </div>
  );
};

export default MobileAppDownloadHeader;